<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getGoodsTypeAPI } from '@/api/common'
import { getImgArr } from '@/utils/util'

export default {
  props: {
    businessIdArr: {
      type: Array,
      default: () => []
    },
    selectedRowKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ...api.command.getState(),
      // selectedRowKeys: [],
      // 商品类型
      goodsType: []
    }
  },
  // computed: {},
  // watch: {
  //   businessIdArr() {
  //     if (this.selectedRowKeys !== this.businessIdArr) {
  //       this.selectedRowKeys = this.businessIdArr
  //     }
  //   },
  // },
  mounted() {
    getGoodsTypeAPI().then(res => {
      const data = [
        {
          name: '全部',
          value: ''
        },
        ...res.map(item => {
          return {
            ...item,
            name: item.label
          }
        })
      ]
      this.goodsType = data
    })
    // this.selectedRowKeys = this.businessIdArr
    api.command.getList.call(this, {
      url: '/api/farmRecommendCommunity/getBusinessPage',
      params: {
        businessType: '1'
      },
      current: 1
    })
  },
  methods: {
    sumbit() {
      this.selectedRowKeys = this.$refs.table.$refs.table.selectedRowKeys
      let selectedRowKeys = this.$refs.table.$refs.table.selectedRowKeys
      let selectedRows = this.$refs.table.$refs.table.selectedRows
      this.$emit('success', { selectedRowKeys, selectedRows })
    },
    getColumns() {
      return [
        {
          dataIndex: 'bannerUrl',
          title: '图片',
          align: 'center',
          customRender: function (value) {
            const imgArr = getImgArr(value)

            return <img class="img" src={imgArr[0] || ''} />
          }
        },
        {
          dataIndex: 'name',
          title: '商品名称',
          align: 'center'
        }
        // {
        //   title: '操作',
        //   type: 'buttonGroup',
        //   disablePermissionVerification: true,
        //   typeData: ({ records }) => {
        //     return [
        //       {
        //         name: '选择',
        //         onClick: () => {
        //           this.$emit('success', JSON.parse(JSON.stringify(records)))
        //         },
        //       },
        //     ]
        //   },
        // },
      ]
    },
    getHeader() {
      const { goodsType } = this
      return [
        {
          name: '商品类型',
          type: 'select',
          placeholder: '商品类型',
          key: 'fromType',
          defaultValue: '',
          typeData: goodsType
        },
        {
          name: '商品名称',
          type: 'input',
          placeholder: '商品名称',
          key: 'name'
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        ref="table"
        // showRowSelect={false}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        records={this.records}
        rowKey="businessId"
        class="table"
        selectedRowKeys={this.selectedRowKeys}
        // rowSelection={{
        //   getCheckboxProps: (record) => {
        //     return {
        //       props: {
        //         disabled: this.businessIdArr.includes(record.businessId),
        //       },
        //     }
        //   },
        // }}
        resetDefaultData={{
          fromType: ''
        }}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.template-card {
  min-height: 0;
}

.table {
  .img {
    width: 50px;
    height: 50px;
  }
}

/deep/ .header-box {
  margin-left: 0;
}
/deep/.ant-table-body {
  max-height: 500px;
}
</style>
