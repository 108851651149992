/**
 * @description 获取商品类型字典
 */
export const getGoodsTypeAPI = async () => {
  const res = [
    {
      label: '农特产',
      value: '1'
    },
    {
      label: '吃好喝好',
      value: '2'
    },
    {
      label: '农事体验',
      value: '4'
    },
    {
      label: '景点',
      value: '12'
    },
    {
      label: '酒店',
      value: '13'
    }
  ]
  console.log('获取商品类型字典', res)
  return res
}
